<script>
import "@/views/Landing/style/content.scss";

export default {
  data: () => ({
    phone_number: null,
    name: null,
    organization: null,
    showDefault: true,
    showCallbackForm: false,
    callbackSuccess: false,
  }),
  emits: [
    'sendQuestion',
    'changeState',
  ],
  name: "common-content",
  computed: {
    getScrollAnimate() {
      return window.innerWidth <= 1152 ? {} : { enterClass: 'fadein', leaveClass: 'fadeout' };
    },
  },
  methods: {
    send(event) {
      this.callbackSuccess = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const form = parent.querySelector('.callback-form');

      form.classList.remove('fadeOpacity');
      form.classList.add('fadeOpacityNone');

      this.$emit('sendQuestion', {phone: this.phone_number, name: this.name, organization: this.organization});

      setTimeout(() => {
        this.showCallbackForm = false;

        const finish = parent.querySelector('.finish-form');

        finish.classList.add('fadeOpacity');
      }, 500);
    },
    openForm(event) {
      this.showCallbackForm = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const common = parent.querySelectorAll('.common-form');

      common.forEach(el => {
        el.classList.add('fadeOpacityNone');
      });

      setTimeout(() => {
        this.showDefault = false;

        const form = parent.querySelector('.callback-form');

        form.classList.add('fadeOpacity');
        form.scrollIntoView(false);
      }, 500);
    },
  },
}
</script>

<template>
  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <h2 class="header-title">Цифровой помощник решения деловых вопросов</h2>

        <span>Управление информацией и бизнес-процессами в единой системе</span>

        <p>Удобный сервис работы с людьми и организациями,
          подходит для сотрудников ведомств и бизнеса</p>

        <div class="group">
          <button @click="$emit('changeState', 'business')" class="space">
            Бизнес
            <img src="@/views/Landing/images/icon/arrow_forward_ios.svg" alt="" />
          </button>
          <!--        <button @click="$emit('changeState', 'trading')" class="space">-->
          <!--          Бизнес с юридическими лицами-->
          <!--          <img src="@/views/Landing/images/icon/arrow_forward_ios.svg" alt="" />-->
          <!--        </button>-->
          <button @click="$emit('changeState', 'department')" class="space">
            Муниципалитет
            <img src="@/views/Landing/images/icon/arrow_forward_ios.svg" alt="" />
          </button>
        </div>
      </div>

      <img src="@/views/Landing/images/common/common-1-img.svg" alt=""/>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section column">
      <div class="column">
        <h2>Открытость и прозрачность</h2>

        <div class="description-container">
        <span class="description-item">
          <span class="bold">Удобная система коммуникации</span> для работы
        </span>

          <span class="description-item">
          <span class="bold">Управление</span> делами и процессами организации
        </span>

          <span class="description-item">
          <span class="bold">Личный кабинет</span> ведения процессов
        </span>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper half-bg" v-animateonscroll="getScrollAnimate">
    <div class="half" />
    <div class="section" style="z-index: 2">
      <div class="column" style="z-index: 2">
        <div class="description-container" style="z-index: 2">
          <h2>Из любого устройства и любой точки мира</h2>

          <span class="description-item">
          <span class="bold">Через ВЕБ-браузер</span> и мобильное приложение
        </span>

          <span class="description-item">
          <span class="bold">Управление делами</span> и бизнес-процессами
        </span>

          <span class="description-item">
          <span class="bold">Оперативный поток</span> информации
        </span>
        </div>
      </div>

      <img src="@/views/Landing/images/tel.svg" alt="" style="z-index: 2" />
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <h2>Подключите своих сотрудников и <span style="color: var(--landing-color-blue)">ПАРТНЕРОВ</span> для общения</h2>

        <span class="description-item normal">
         Если вы захотите подключить нового сотрудника к сервису — легко сами даете доступ. И совсем неважно, работает ли он из вашего офиса или находится в 5 часовых поясах от вас
      </span>
      </div>

      <img src="@/views/Landing/images/Group_121.svg" alt=""/>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>Регистрация в системе</h2>

          <span class="normal">происходит с действительными реквизитами организации</span>

          <div class="schema">
            <div class="schema-item">
              <span class="text">Оставьте заявку на сайте, пройдите проверку</span>

              <span class="number line">1</span>
            </div>

            <div class="schema-item reverse">
              <span class="text">Получите логин и пароль от личного кабинета</span>

              <span class="number line">2</span>
            </div>

            <div class="schema-item">
              <span class="text">Начните вести бизнес-процессы и приятной работы!</span>

              <span class="number">3</span>
            </div>
          </div>

          <button @click="this.$router.push('/authorization?register=true')">
            Подать заявку сейчас!
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper cloud" v-animateonscroll="getScrollAnimate">
    <img class="left" src="@/views/Landing/images/common/left.svg" alt=""/>
    <img class="right" src="@/views/Landing/images/common/right.svg" alt=""/>

    <div class="section" style="z-index: 2">
      <div class="column" style="z-index: 2">
        <div class="center" style="z-index: 2">
          <h2>Работа через веб-браузер и приложение«Обращайся»</h2>

          <div class="price-wrapper">
<!--            <div class="price-container">-->
<!--              <span class="sum">8 001 ₽</span>-->
<!--              <span class="type">подписка</span>-->
<!--              <span class="type-duration">месяц</span>-->
<!--            </div>-->

            <div class="price-container">
              <span class="sum">52 000 ₽</span>
              <span class="type">подписка</span>
              <span class="type-duration">квартал</span>
            </div>

            <div class="price-container">
              <span class="sum">79 999 ₽</span>
              <span class="type">подписка</span>
              <span class="type-duration">полгода</span>
            </div>

            <div class="price-container year">
              <span class="sum">130 000 ₽</span>
              <span class="type">подписка</span>
              <span class="type-duration">год</span>
              <span class="banner">ЭКОНОМИЯ ДО 50%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>Также в подписку включено</h2>

          <div class="list-container">
            <div class="list-item">
              <img src="@/views/Landing/images/icon/update.svg" alt=""/>
              <p>Автоматические обновления</p>
            </div>
            <div class="list-item">
              <img src="@/views/Landing/images/icon/sync_alt.svg" alt=""/>
              <p>Клиент-серверный вариант работы для повышения надежности и скорости работы</p>
            </div>
            <div class="list-item">
              <img src="@/views/Landing/images/icon/support_agent.svg" alt=""/>
              <p>Техническая поддержка</p>
            </div>
            <div class="list-item">
              <img src="@/views/Landing/images/icon/school.svg" alt=""/>
              <p>Обучение</p>
            </div>
          </div>

          <div class="images-with-text">
            <img src="@/views/Landing/images/logo.svg" alt=""/>
            <p>-</p>
            <p class="long-text">Официальный Партнер ПАО «СБЕРБАНК»</p>
            <img class="sber" src="@/views/Landing/images/sber_logo.svg" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>Единая система управления бизнес-процессами</h2>
          <h2 class="gray">Цифровая копия вашего бизнеса</h2>

          <div class="tile-container">
            <div class="tile">Управление вашей деятельности</div>
            <div class="tile">Онлайн-бизнес</div>
            <div class="tile">Бизнес-сеть</div>
            <div class="tile">Деловая сеть</div>
            <div class="tile">Онлайн-офисы продажи</div>
            <div class="tile">Онлайн-приемная</div>
            <div class="tile">Цифровой многофункциональный центр</div>
            <div class="tile">Торговая площадка с прямым общением</div>
            <div class="tile">Обмен информацией и взаимодействия</div>
            <div class="tile">Деловой портфель онлайн-бизнеса и точка общения</div>
            <div class="tile">Бизнес сеть для решения деловых вопросов</div>
            <div class="tile">Сообщество для решения деловых вопросов</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper contact-form" v-animateonscroll="getScrollAnimate">
    <div class="section common contact-form">
      <div class="column">
        <div class="center">
          <h2 v-if="showDefault" class="common-form">Появились вопросы?</h2>

          <button v-if="showDefault" @click="openForm" class="common-form">
            Связаться с нами
          </button>

          <div class="callback-form" v-if="showCallbackForm">
            <span>Заполните форму, и мы с Вами свяжемся</span>

            <input class="number"
                   type="text"
                   v-model="name"
                   placeholder="Ваше имя"
            />

            <input class="number"
                   type="text"
                   v-model="organization"
                   placeholder="Организация"
            />

            <input class="number"
                   type="tel"
                   v-maska="'+7 (###) ###-##-##'"
                   v-model="phone_number"
                   placeholder="+7 (000) 000-00-00"
            />

            <button @click="send" :disabled="(phone_number === '' || phone_number === null) || (name === '' || name === null)">
              Отправить
            </button>
          </div>

          <h2 v-if="callbackSuccess" class="finish-form">Спасибо за отклик!</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>