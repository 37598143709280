import {createStore} from 'vuex'
import AppealsCitizen from "@/store/modules/Appeals/AppealsCitizen";
import AppealsCard from "@/store/modules/Appeals/AppealsCard";
import ListCitizen from "@/store/modules/ListCitizen/ListCitizen";
import AppealsModalCloseTask from "@/store/modules/Appeals/AppealsModalCloseTask";
import AppealsModalDenail from "@/store/modules/Appeals/AppealsModalDenail";
import ListCard from "@/store/modules/ListCitizen/ListCard";
import channelModule from '@/store/modules/channel/channel';
import teamModule from '@/store/modules/team/team';
import authModule from '@/store/modules/auth';
import registrationModule from '@/store/modules/registration/registration';
import constructorModule from '@/store/modules/constructorDocuments/constructorDocuments';
import RegisterNpa from "@/store/modules/RegisterNPA/RegisterNpa";
import departmentModule from '@/store/modules/department/department';
import insideChatModule from '@/store/modules/insideChat/chat';
import wallet from "@/store/modules/Wallet/wallet";
import Notify from "@/store/modules/Notify/main";
import Feed from "@/store/modules/Feed/main";
import Microtender from "@/store/modules/microtender/main";
import Promotion from "@/store/modules/promotion/main";
import Public from "@/store/modules/public/main";
import CommonBooks from "@/store/modules/books/main";
import {API_URL} from "@/core/constants";
import {serverError, serverFailedAccess} from "@/main";

export default createStore({
  state: {
    sidebarGroups: [
      {
        group_name: '',
        data: [
          {
            id: 1,
            icon: 'home',
            name: 'Мой канал',
            route: '/my-channel'
          },
          // {
          //   id: 2,
          //   icon: 'newspaper',
          //   name: 'Новости',
          //   route: '/feed'
          // },
          // {
          //   id: 16,
          //   icon: 'newspaper',
          //   name: 'Лента',
          //   route: '/feed-common'
          // },
          // {
          //     id: 3,
          //     icon: 'groups',
          //     name: 'Список граждан',
          //     route: '/list-citizen'
          // },
          {
            id: 4,
            icon: 'business_center',
            name: 'Заявки с приложения',
            route: '/appeals-citizens'
          },
          // {
          //     id: 5,
          //     icon: 'space_dashboard',
          //     name: 'Конструктор документов',
          //     route: '/document-constructor'
          // },
          {
            id: 6,
            icon: 'description',
            name: 'Мои документы',
            route: '/register-npa'
          },
          {
            id: 7,
            icon: 'chat',
            name: 'Мессенджер',
            route: '/chat/all'
          },
          {
            id: 13,
            icon: 'diversity_2',
            name: 'Организации',
            route: '/community'
          },
          {
            id: 8,
            icon: 'question_answer',
            name: 'Чат с организациями',
            route: '/department-interaction/all'
          },
          // {
          //     id: 9,
          //     icon: 'map',
          //     name: 'Карта',
          //     route: '/map'
          // },
          // {
          //   id: 10,
          //   icon: 'dns',
          //   name: 'Мои сервисы',
          //   route: '/info-systems',
          // },
          {
            id: 11,
            icon: 'conveyor_belt',
            name: 'Микроторги',
            route: '/trade-aggregator',
          },
          {
            id: 15,
            icon: 'campaign',
            name: 'Маркировка рекламы',
            route: '/promotion',
          },
          {
            id: 17,
            icon: 'headset_mic',
            name: 'Тех. поддержка',
            route: '/department-interaction/support',
          },
          // {
          //     id: 12,
          //     icon: 'today',
          //     name: 'Разместить информацию',
          //     route: '/department-interaction/promo'
          // },
          // {
          //     id: 14,
          //     icon: 'auto_stories',
          //     name: 'Похозяйственный учёт',
          //     route: '/books',
          // },
          /*
            {
                icon: 'message',
                name: 'Сообщения',
                route: '/section-development',
                opacity: true
            },
            {
                icon: 'videocam',
                name: 'ВКС',
                route: '/section-development',
                opacity: true
            },
            {
                icon: 'pie_chart',
                name: 'Статистика',
                route: '/section-development',
                opacity: true
            }
            ,
            {
                icon: 'crisis_alert',
                name: 'Таргет',
                route: '/section-development',
                opacity: true
            }
            ,
            {
                icon: 'videogame_asset',
                name: 'Офисные игры',
                route: '/section-development',
                opacity: true
            }, */
        ]
      }
    ],
    linkDetails: {
      channelId: '',
      typeName: '',
      detailsId: '',
    },
    autoMessageData: {},
  },
  getters: {
    getLinkDetailsChannelId(state) {
      return state.linkDetails.channelId;
    },
    getLinkDetailsTypeName(state) {
      return state.linkDetails.typeName;
    },
    getLinkDetailsId(state) {
      return state.linkDetails.detailsId;
    },
    getAutoMessageData(state) {
      return state.autoMessageData;
    },
  },
  mutations: {
    setLinkDetails(state, data) {
      state.linkDetails.detailsId = data.detailsId;
      state.linkDetails.typeName = data.detailsType;
      state.linkDetails.channelId = data.channelId;
    },
    setAutoMessageData(state, data) {
      state.autoMessageData = data;
    },
  },
  actions: {
    async landingCallback(ctx, payload) {
      await fetch(
          `${API_URL}managers/ticket-apply`,
          {
            method: 'POST',
            body: payload,
          }
      )
          .then((response) => {
            if (!response.ok) {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
  },
  modules: {
    authModule,
    channelModule,
    teamModule,
    registrationModule,
    constructorModule,
    departmentModule,
    insideChatModule,
    appeals: AppealsCitizen,
    card: AppealsCard,
    list: ListCitizen,
    listCard: ListCard,
    modalCloseTasc: AppealsModalCloseTask,
    modalCloseDenail: AppealsModalDenail,
    register: RegisterNpa,
    wallet,
    Notify,
    Feed,
    Microtender,
    Public,
    CommonBooks,
    Promotion,
  }
})
